import { useMemo } from 'react';
import {
  ReactionsConfig,
  resolvePostReactionsConfig,
} from '../containers/post-reactions/post-reactions-config.utils';

export const useReactionsConfig = (category: any): ReactionsConfig => {
  return useMemo(() => {
    const reactionsConfig = resolvePostReactionsConfig({
      postInteraction: category.postInteraction ?? undefined,
      mainPostReaction: category.mainPostReaction ?? undefined,
      additionalPostReactions: category.additionalPostReactions ?? [],
    });

    return reactionsConfig;
  }, [category.mainPostReaction, category.additionalPostReactions, category.postInteraction]);
};
