import classNames from 'classnames';
import React from 'react';
import { CommentIconSmall } from '../../icons/comment-icon-small';
import IconTooltip from '../../icon-tooltip';
import { CommentDisabledIconSmall } from '../../icons/comment-disabled-icon-small';
import { CommentDisabledIcon } from '../../icons/comment-disabled-icon';
import styles from './comment-button.scss';
import Link from '../../link/internal-link';
import CounterNumber from '../../counter-number';

type CommentButtonProps = {
  postLink?: string;
  post: any;
  t: any;
  type: 'metadata' | 'action';
  isLink?: boolean;
};

export const CommentButton: React.FC<CommentButtonProps> = ({
  post,
  postLink,
  t,
  type,
  isLink,
}) => {
  const isMetadataComponent = type === 'metadata';
  const CommentsDisabledIcon = isMetadataComponent ? CommentDisabledIconSmall : CommentDisabledIcon;

  const commentsCount = (
    <div className={styles.numberElementWithIcon} data-hook="post-list-item__comment-count">
      {post.isCommentsDisabled ? (
        <IconTooltip
          text={t('post-list-item.post-closed')}
          className={classNames(styles.icon, styles.tooltip, {
            [styles.metadata]: isMetadataComponent,
          })}
        >
          <CommentsDisabledIcon
            className={classNames(styles.icon, 'forum-icon-fill', {
              [styles.metadata]: isMetadataComponent,
            })}
            title={undefined}
          />
        </IconTooltip>
      ) : (
        <CommentIconSmall
          title={t('post-list-item.total-comments', {
            count: post.totalComments || 0,
          })}
          className={classNames(styles.icon, 'forum-icon-fill', {
            [styles.metadata]: isMetadataComponent,
          })}
        />
      )}
      {isMetadataComponent ? (
        <span>
          <CounterNumber initialValue={post.totalComments} entityId={post._id} totalComments />
        </span>
      ) : (
        <span className={classNames(styles.numberElementText)}>{t('post-list-item.comment')}</span>
      )}
    </div>
  );

  return isLink ? (
    <Link
      className={classNames(
        'forum-text-color',
        isMetadataComponent ? 'forum-link-hover-color' : styles.link,
      )}
      to={`${postLink}`}
      data-hook="post-list-item__comment-count"
    >
      {commentsCount}
    </Link>
  ) : (
    commentsCount
  );
};
